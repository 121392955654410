<template>
  <v-container>
    <v-row justify="center">
      <v-col class="text-center" md="6">
        <v-btn block color="warning" outlined @click="$router.push('/menu')"
          >volver</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <v-row>
        <v-col md-6>
        <h3>BODEGA PRINCIPAL(CAPITANIA)</h3>
      </v-col>
      <v-col md-6>
        <v-btn color="warning" block @click="entregaMultipleDialogState=true">asignacion multiples items</v-btn>
      </v-col>
    </v-row>
        <v-text-field
          dense
          label="BUSCAR IMPLEMENTO"
          v-model="buscar_items"
        ></v-text-field>
        <v-data-table
          dense
          :headers="headers_bodega"
          :items="items_bodega"
          class="elevation-5"
          sort-by="nSello"
          :search="buscar_items"
          item-key="id_asignacion"
        >
          <template v-slot:[`item.fecha_asignacion`]="{ item }">
            <span>{{
              $dayjs(item.fecha_asignacion).format("DD-MM-YYYY")}}</span>
          </template>
          <template v-slot:[`item.fecha_estado`]="{ item }">
            <span>{{ $dayjs(item.fecha_estado).format("DD-MM-YYYY") }}</span>
          </template>
          <template v-slot:[`item.nombre_tipo_item`]="{ item }">
            <span>{{ item.nombre_tipo_item }} ({{ item.nombre_subtipo }})</span>
          </template>
          <template v-slot:[`item.accion`]>
            <v-btn color="success" x-small>asigar</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <entregaMultipleDialog 
      :dialogEntrega="entregaMultipleDialogState" 
      @cerrar="cerrardialogmultiple">
    </entregaMultipleDialog>
  </v-container>
</template>

<script>
import axios from "axios";
import entregaMultipleDialog from "../components/entregaMultipleDialog.vue";
export default {
  components:{
    entregaMultipleDialog
  },
  data() {
    return {
      entregaMultipleDialogState: false,
      buscar_items: "",
      items_bodega: [],
      headers_bodega: [
      { text: "Item", value: "type.name" },
        { text: "Marca", value: "make.name" },
        { text: "Modelo", value: "model.name" },
        { text: "Serie", value: "serial_number" },
        { text: "N°Interno", value: "internal_identifier" },
        { text: "Talla", value: "size" },
        { text: "Asignado", value: "fecha_asignacion" },
        { text: "Revisado", value: "fecha_estado" },
        { text: "Estado", value: "condition.condition" },
        { text: "Accion", value: "accion" },
      ],
      items_bodega: [],
    };
  },
  methods: {
    consultabodegaprincipal: function (data) {
      let personaConsultada = 3;
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/api/firefighter/get/${personaConsultada}`)
        .then((response) => {
          console.log("ss",response.data[0]);
          this.items_bodega = response.data[0].assigned_items
        });
    },
    cerrardialogmultiple:function(e){
      this.entregaMultipleDialogState = e
    }
  },
  created() {
    this.consultabodegaprincipal();
  },
};
</script>
