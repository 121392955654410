<template>
  <v-dialog
      v-model="dialogEntrega"
      persistent
      max-width="900px"
     
  >
      <v-form ref="formEntregaMultiple"  v-model="formValid">
          
          <v-card>
              <v-card-title >
                  REGISTRO DE ENTREGA MULTIPLES ITEMS
              </v-card-title>
              <v-card-text >
                <v-row>
                    <v-col md="4" cols="12" >
                        <v-text-field
                             label="Entregado por"
                             name="entregado-por"
                             id="entregadoPorPersona"
                             :rules="seleccionarRule"
                             required
                             dense
                        ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                        <v-text-field
                            label="Fecha"
                            type="date"
                            v-model="entrega.assignment_date"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                        <v-text-field
                            label="Folio"
                            :rules="seleccionarRule"
                            required
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col md="4" cols="12">
                        <v-autocomplete
                            label="Entregado A"
                            v-model="entrega.fk_firefighter"
                            :items="nombrecompuestobombero"
                            :rules="seleccionarRule"
                           
                            variant="underlined"
                            item-text="nombrecompleto"
                            item-value="id"
                            dense
                        ></v-autocomplete>
                    </v-col>,
                    <v-col md="4" cols="12">
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                    <v-col md="12" cols="12" class="text-center orange lighten-4 mb-4" >
                        <h4>DETALLE ITEM ENTREGADOS</h4>
                    </v-col>
                </v-row>

                <v-row dense v-for="(linea, index) in alllineas" :key="index">
                    <v-col md="11" cols="12">
                    <v-autocomplete
                        label="descripcion"
                        :items="eppcompuesto"
                        v-model="alllineas[index].id"
                        :rules="seleccionarRule"
                        item-text="descMasTalla"
                        item-value="id_item"
                        required
                        dense
                        @change="sumarItemsId(index)"
                    ></v-autocomplete>
                    </v-col>

                    <v-col md="1" cols="12">
                    <v-btn color="warning" @click="borralinea(index)">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    </v-col>
                    
                </v-row>
                <v-btn @click.prevent="sumaunalinea" color="success">
                    <v-icon>mdi-plus</v-icon>
                    </v-btn>
               

                

              </v-card-text>
              <v-card-actions>
                  <v-btn @click="cerrarDialog" color="warning" outlined dark>cancelar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="guardarNuevaAsignacion" :disabled="!formValid" color="success">Guardar</v-btn>
              </v-card-actions>
          </v-card>

      </v-form>
  </v-dialog>
</template>

<script>


import axios from "axios";
export default {
 
    name:"entrega_epp_trabajador",
    props:{
        dialogEntrega:Boolean,
        datos:Object
    },
    data() {
        return {
            seleccionarRule: [(v) => !!v || "Debes seleccionar una opcion"],
            formValid: false,
            entrega:{
                assignment_date:"",
                fk_firefighter:"",
                items_ids:[]
             },
            alllineas:[{
                id:"",
            }
        ],

        }
    },
    methods:{
        sumaunalinea:function(){
            this.alllineas.push({
                id:"",
            })
        },
        sumarItemsId:function(index){
                this.entrega.items_ids.push(this.alllineas[index].id);
                //this.nuevoItem = ''; // Limpiar el campo después de agregar
            
        },
        borralinea:function(linea){
            console.log(linea);
            this.alllineas.splice(linea,1)
        },
        actualizaNombreBodegaQueEntrego:function(){
            let bodega = this.$store.state.bodegas.filter((dat)=>{
                 return dat.id == this.entrega.idBodegaQueEntrego
            })
            this.entrega.nombreBodegaqueEntrego = bodega[0].nombreBodega
        },
        computedNombreEntregadoA:function(){
            let elEmpleado = this.$store.state.empleados.filter(x=>{
                return x.rut == this.entrega.rutEntregadoA
            })
            this.entrega.nombreEntregadoA = elEmpleado[0].nombre_completo
        },
        cerrarDialog:function(){
            this.$emit("cerrar", false)
            this.$refs.formEntregaMultiple.reset()
            this.alllineas=[{
                detalleEntrega:"",
                entregadoCantidad:null
            }]
        },
        guardarNuevaAsignacion:function(){
            axios.post(`${process.env.VUE_APP_BASE_URL}/api/item/assignItems`, this.entrega).then((response) => {
                this.dialogCrearHerramienta = false;
                this.$swal("La asignacion de Items fue realizado correctamente FZJP");
    });
            this.cerrarDialog()
        }

    },
    computed:{

        lasBodegas:function(){
            return this.$store.state.bodegas
        },
        usernombre:function(){
            this.entrega.entregadopor = this.$store.state.userlogeado.displayName
        },
        eppcompuesto:function(){
            let compuesto = this.$store.state.itemAsiggnable.reduce((acc, item)=>{
                item.descMasTalla = item.type.name+" - "+item.make.name + " " +item.model+ " Talla "+item.size+" N°serie"+item.serial_number
                acc.push(item)
                return acc
            },[])
            return compuesto
        },
        nombrecompuestobombero:function(){
            let nombrebomberocompuesto = this.$store.state.bomberos.reduce((acc,item)=>{
                item.nombrecompleto = item.name+" "+item.fathersSurname+" "+item.mothersSurname
                acc.push(item)
                return acc
            },[])
            return nombrebomberocompuesto
        }
        
    }
}
</script>